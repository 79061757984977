<template>
    <div id="konteynerMap">
        <b-col>
            <b-row>
                <b-col cols="12" md="3" class="p-0 p-3" style="position: relative;height: calc(100vh - 56px);overflow: scroll;">
                    <!-- <b-form-select 
                        required 
                        @change="kontrol($event)" 
                        id="input-1" 
                        class="mb-3"
                        v-model="secilen_bolge"
                        :options="bolgeler"></b-form-select> -->
                    <b-list-group v-if="konteynerlar">
                        <b-list-group-item 
                            class="hover" 
                            v-for="konteyner in konteynerlar" 
                            :key="konteyner.kk_id" 
                            style="background-color: #FFF;">
                            <b-badge variant="primary" class="hover" @click="ortala(konteyner.kk_id)">
                                <b-icon-map class="mr-2"></b-icon-map>ID: {{konteyner.kk_id}}
                            </b-badge>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
                
                <b-col cols="12" md="9" class="p-0">
                    <gmap-map
                        :center="secilenKonteyner ? secilenKonteyner : center"
                        :zoom="zoom"
                        style="width:100%;  height: calc(100vh - 56px);"
                        :options="options"
                        @click="tikla">
                        <gmap-marker
                            v-if="secilenKonteyner"
                            :position="secilenKonteyner"
                            @click="() => {
                                tikla();
                                secilenKonteyner.info = true;    
                            }">
                            <gmap-info-window
                                :options="infoOptions"
                                :position="infoPosition"
                                :opened="secilenKonteyner.info"
                                :content="infoContent"
                                @closeclick="secilenKonteyner.info=false">
                                <div style="width: 200px;">
                                    <div> 
                                        <p style="font-weight: 700;font-size: 15px;">
                                            <b>ID:</b> <b-badge class="calloutTitle">#{{secilenKonteyner.kk_id}}</b-badge>
                                        </p>
                                    </div>
                                    <div style="margin-top: 5px;margin-bottom: 15px;font-size: 14px;"> 
                                        <p><b>Adres:</b> {{secilenKonteyner.kk_adres}}</p>
                                    </div>
                                    <div style="margin-top: 5px;margin-bottom: 15px;font-size: 14px;"> 
                                        <p><b>Bölge:</b> {{bolgeAdGetir(secilenKonteyner.kk_bolge)}}</p>
                                    </div>
                                    <div style="margin-top: 5px;margin-bottom: 15px;font-size: 14px;"> 
                                        <p class="mb-0"><b>Süre:</b> {{secilenKonteyner.kk_gun}} günde bir</p>
                                        <p><b>Toplama:</b> {{secilenKonteyner.data.sonTarih}}</p>
                                    </div>
                                    <div class="text-right">
                                        <b-button variant="primary" size="sm" v-b-modal.modal-1>Güncelle</b-button>
                                    </div>
                                </div>
                            </gmap-info-window>
                        </gmap-marker>
                    </gmap-map>
                </b-col>
            </b-row>
        </b-col>

        <b-modal id="modal-1" hide-footer hide-header v-if="secilenKonteyner">
            <b-form @submit.prevent="kaydet">
                <b-form-group
                    id="input-group-1"
                    label="Adres"
                    label-for="input-1">
                    <b-form-input
                    id="input-1"
                    v-model="secilenKonteyner.kk_adres"
                    required
                    type="text"
                    placeholder="Adres bilgisi girin."
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-group-2"
                    label="Süre"
                    label-for="input-2">
                    <b-form-input
                    id="input-2"
                    v-model="secilenKonteyner.kk_gun"
                    required
                    type="text"
                    placeholder="Kaç günde bir toplanacak"
                    ></b-form-input>
                </b-form-group>
                <b-row>
                    <b-col>
                        <b-form-group
                            id="input-group-3"
                            label="Enlem"
                            label-for="input-3">
                            <b-form-input
                            id="input-3"
                            v-model="secilenKonteyner.kk_lat"
                            required
                            type="text"
                            placeholder="Enlem bilgisi girin."
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group
                            id="input-group-4"
                            label="Boylam"
                            label-for="input-4">
                            <b-form-input
                            id="input-4"
                            v-model="secilenKonteyner.kk_lng"
                            required
                            type="text"
                            placeholder="Boylam bilgisi girin."
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-form-group
                    id="input-group-5"
                    label="Bölge Seçin"
                    label-for="input-5">
                    <b-form-select 
                        required 
                        id="input-5" 
                        class="mb-3"
                        v-model="secilenKonteyner.kk_bolge"
                        :options="bolgeler"></b-form-select>
                </b-form-group>
                <b-button variant="primary" type="submit">Kaydet</b-button>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
// import Swal from 'sweetalert2';

export default {
    data(){
        return {
            secilen_bolge: null,
            secilenKonteyner: null,
            konteynerlar: null,
            center: {
                lat: 38.4174357,
                lng: 27.1560596
            },
            zoom: 13,
            options:{
                disableDefaultUI:false,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [
                            { visibility: "off" }
                        ]
                    }
                ]
            },
            infoPosition: null,
            infoContent: null,
            infoOpened: true,
            infoCurrentKey: null,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: 0
                }
      
            },
        }
    },

    methods: {
        kontrol(){

        },
        ortala(id){
            var index = this._konteynerlar.findIndex(c => c.kk_id == id);
            if(index > -1){
                this.secilenKonteyner = this._konteynerlar[index];
            }
        },
        tikla(){

        },

        kaydet(){
            var konteyner = {
                kk_id: this.secilenKonteyner.kk_id,
                kk_lat: this.secilenKonteyner.kk_lat,
                kk_lng: this.secilenKonteyner.kk_lng,
                kk_adres: this.secilenKonteyner.kk_adres,
                kk_bolge: this.secilenKonteyner.kk_bolge,
                kk_gun: this.secilenKonteyner.kk_gun,
            }
            this.$store.dispatch("konteynerEdit", konteyner).then(r => {
                this.$store.commit("initDurum", {status: r.status, message: r.message});
            })
        },
        bolgeAdGetir(bolge_id){
            var index = this.bolgeler.findIndex(c => c.value == bolge_id);
            return index > -1 ? this.bolgeler[index].text : null;

        }
    },

    computed: {
        bolgeler(){
            var data = [];
            data.push({
                value: null,
                text: "Bölge Seç"
            })
            this.$store.state.bolgeler.map(c =>{
                data.push({
                    value: c.bolge_id,
                    text: c.bolge_ad
                })
            });
            return data;
        },
        _konteynerlar(){
            return this.$store.state.konteynerlarSortId.map(c => {
                return {
                    lat: Number(c.kk_lat),
                    lng: Number(c.kk_lng),
                    kk_id: c.kk_id,
                    kk_bolge: c.kk_bolge,
                    order: c.kk_order,
                    info: true,
                    kk_gun: c.kk_gun,
                    kk_lat: c.kk_lat,
                    kk_lng: c.kk_lng,
                    kk_adres: c.kk_adres,
                    data: c.data
                }
            });
        }
    },

    mounted(){
        this.konteynerlar = {...this._konteynerlar};
    }
}
</script>

<style>
.hover:hover{
    cursor: pointer;
}
.calloutTitle{
    position: relative;
    top: -1px;
}
</style>